const colors = {
  black: `#4a4a4a`,
  grey: `#7A7B7A`,
  lightgrey: `#EFEFEF`,
  green: `#0D3D18`,
  lilac: `#8c65b3`,
  lavender: `#b190d5`,
  wisteria: `#ccb2e5`,
  accent: `#ffb238`,
  success: `#37b635`,
  warning: `#ec1818`,
  ui: {
    border: `#ede7f3`,
    bright: `#e0d6eb`,
    light: `#f5f3f7`,
    whisper: `#fbfafc`,
  },
}

export default colors
