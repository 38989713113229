import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { mq } from "../utils/presets"
import styled from "styled-components"
import logo from "../images/hauenstein-logo.svg"
import MenuItems from "../components/menuitems"
import Headroom from "react-headroom"

const Logo = styled.img`
  width: 220px;
  height: auto;
`

const Nav = styled.nav`
  display: flex;
  flex-flow: row;
  padding: 0.6rem 1rem 1rem;
  /* max-width: 1080px; */
  max-width: 1475px;
  margin: 0 auto;
  justify-content: space-between;
  height: 100px;
  a {
    flex: 0 1 auto;
    line-height: 0;
    display: block;
  }
  ${mq.xl} {
    h1 {
      text-align: left;
      margin-left: 1rem;
    }
  }
`

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
        }
        wordpressWpApiMenusMenusItems(name: { eq: "Hauptmenu" }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={data => (
      <header>
        <Headroom style={{ boxShadow: "0 0 25px rgba(0,0,0,.25)" }}>
          <Nav>
            <Link to="/">
              <Logo
                src={logo}
                alt={data.wordpressSiteMetadata.name}
                className="logoblack"
              />
            </Link>
            <MenuItems />
          </Nav>
        </Headroom>
      </header>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
