/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  image,
  jobtype,
  modified,
  jobposting,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  console.log(jobposting)

  const metaDescription = description || site.siteMetadata.description

  const schemaOrgJobPosting = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: title,
    description: description,
    identifier: {
      "@type": "PropertyValue",
      name: "Hauenstein Estriche GmbH",
    },
    datePosted: modified,
    employmentType: jobtype,
    hiringOrganization: {
      "@type": "Organization",
      name: "Hauenstein Estriche GmbH",
      sameAs: "https://www.hauenstein-estriche.de/",
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Am Mailand 9",
        addressLocality: "Hummeltal",
        addressRegion: "Bayern",
        postalCode: "95503",
        addressCountry: "DE",
      },
    },
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "Hauenstein Estriche",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "google-site-verification",
          content: "qrgewCBRnncZMJCfVVvK4lw7dAWCgduwAzzd2nLMiVE",
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      {jobposting && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJobPosting)}
        </script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
