import React from "react"
import { Link } from "gatsby"
import logowhite from "../images/hauenstein-logowhite.png"
import ral from "../images/ral-guetezeichen.png"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/pro-light-svg-icons"
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons"

const Footer = styled.footer`
  width: 100%;
  background: ${colors.green};
  ${mq.tablet} {
    margin-top: 3rem;
  }
  padding-bottom: 1rem;
`

const FooterInner = styled.div`
  z-index: 1;
  color: white;
  padding: 2rem 1rem 0;
  display: flex;
  flex-flow: row wrap;
  font-size: 0.77rem;
  justify-content: space-between;
  margin-bottom: 3rem;
  .hideonmobile {
    display: none;
  }
  ${mq.tablet} {
    justify-content: flex-start;
  }
  h2 {
    font-size: 1rem;
    margin-bottom: 0.4rem;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
  }
  a {
    color: white;
    text-decoration: none;
  }
  .flex {
    flex: 1 0 auto;
    width: 50%;
    padding-bottom: 2rem;
    ${mq.tablet} {
      padding-bottom: 0;
    }
  }
  .hauensteinlogo {
    order: 2;
  }
  .ral {
    width: 10%;
    order: 1;
    img {
      height: 90px;
    }
  }
  ${mq.xl} {
    .hauensteinlogo {
      order: initial;
    }
    .ral {
      width: 16.6%;
      order: initial;
      img {
        height: initial;
      }
    }
    .hideonmobile {
      display: block;
    }
    .flex {
      flex: 1 0 auto;
      width: 16.6%;
      padding-bottom: 2rem;
      ${mq.tablet} {
        padding-bottom: 0;
      }
    }
    .projekte {
      display: block;
    }
    .faicon {
      display: none;
    }
  }
`

export default () => (
  <React.Fragment>
    <Footer>
      <FooterInner>
        <div className="flex projekte ral" style={{ textAlign: "center" }}>
          <a
            href="https://www.ral-guetezeichen.de/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ral} alt="RAL Gütezeichen" />
          </a>
        </div>

        <div className="flex anschrift hideonmobile">
          <h2>
            <FontAwesomeIcon className="faicon" icon={faMapMarkerAlt} />{" "}
            Anschrift
          </h2>
          Hauenstein Estriche GmbH
          <br />
          Am Mailand 9
          <br />
          95503 Hummeltal
          <br />
        </div>

        <div className="flex kontakt hideonmobile">
          <h2>
            <FontAwesomeIcon className="faicon" icon={faPhone} /> Kontakt
          </h2>
          Tel: <a href="tel:+4992011403">+49 (0) 9201 1403</a>
          <br />
          Fax: <a href="tel:+49920179277">+49 (0) 9201 792 77</a>
          <br />
          <a href="mailto:&#x65;&#x73;&#x74;&#x72;&#x69;&#x63;&#x68;&#x65;&#x40;&#x68;&#x61;&#x75;&#x65;&#x6e;&#x73;&#x74;&#x65;&#x69;&#x6e;&#x2d;&#x65;&#x73;&#x74;&#x72;&#x69;&#x63;&#x68;&#x65;&#x2e;&#x64;&#x65;">
            &#x65;&#x73;&#x74;&#x72;&#x69;&#x63;&#x68;&#x65;&#x40;&#x68;&#x61;&#x75;&#x65;&#x6e;&#x73;&#x74;&#x65;&#x69;&#x6e;&#x2d;&#x65;&#x73;&#x74;&#x72;&#x69;&#x63;&#x68;&#x65;&#x2e;&#x64;&#x65;
          </a>
          <br />
          <Link to="/kontakt#angebot">Angebot einholen</Link>
          <br />
          <a href="https://cloud.hauenstein-estriche.de/Hauenstein-Logo.zip">
            Logo Download (1,6 mb)
          </a>
        </div>

        <div className="flex projekte hideonmobile">
          <h2>Leistungen</h2>
          <Link to="/leistungen#Estriche">Estriche</Link>
          <br />
          <Link to="/leistungen#Beschichtungen">Beschichtungen</Link>
          <br />
          <Link to="/leistungen#Abdichtungen">Abdichtungen</Link>
          <br />
          <Link to="/leistungen#Sanierungen">Sanierungen</Link>
          <br />
          <Link to="/leistungen#Unterbodenausgleich">Unterbodenausgleich</Link>
          <br />
        </div>

        <div className="flex ueber-uns hideonmobile">
          <h2>Über Uns</h2>
          <Link to="/ueber-uns/">Über Uns</Link>
          <br />
          <Link to="/kontakt/">Kontakt</Link>
          <br />
          <Link to="/referenzen/">Referenzen</Link>
          <br />
          <Link to="/datenschutz/">Datenschutz</Link>
          <br />
          <Link to="/impressum/">Impressum</Link>
          <br />
        </div>

        <div
          className="flex projekte hauensteinlogo"
          style={{ alignSelf: "center" }}
        >
          <Link to="/" alt="Startseite">
            <img src={logowhite} alt="Hauenstein Logo" />
          </Link>
        </div>
      </FooterInner>
    </Footer>
  </React.Fragment>
)
