import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { mq } from "../utils/presets"

const MenuList = styled.ul`
  display: none;
  ${mq.desktop} {
    list-style: none;
    display: flex;
    flex-flow: row;
    align-self: flex-end;
    margin-bottom: 0.5rem;
    li {
      display: block;
      margin: 0;
      padding-right: 2rem;
      &:last-child {
        padding-right: 0;
      }
      a {
        font-weight: bold;
        display: block;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        text-decoration: none;
        font-family: "Merriweather";
        font-size: 1.1rem;
        /* font-style: italic; */
        color: #333;
      }
    }
  }
`

const MenuItems = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpApiMenusMenusItems(name: { eq: "Hauptmenu" }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={data => (
      <MenuList>
        {data.wordpressWpApiMenusMenusItems.items.map(item => (
          <li key={item.object_slug}>
            <Link
              to={`/${item.object_slug}`}
              activeStyle={{
                fontWeight: "bold",
                color: "#0D3D18",
              }}
            >
              {item.title}
            </Link>
          </li>
        ))}
      </MenuList>
    )}
  />
)

export default MenuItems
