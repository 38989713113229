/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import FixedBottomMenu from "../components/FixedBottomMenu"
import Footer from "./footer"
import Header from "./header"
import "./layout.scss"
// import SelfCookieConsent from "./SelfCookieConsent"

const Main = styled.main``

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.wordpressSiteMetadata.name} />
        <Main>{children}</Main>
        <Footer />
        <FixedBottomMenu />
        {/* <SelfCookieConsent /> */}
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
