import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { mq } from "../utils/presets"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUsers } from "@fortawesome/free-solid-svg-icons"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import { faImages } from "@fortawesome/free-solid-svg-icons"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faHammer } from "@fortawesome/free-solid-svg-icons"

const FixedBottomMenuWrapper = styled.div`
  position: fixed;
  /* z-index: 9999; */
  bottom: 0;
  background: #fefefe;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: 0.6rem;
  ul {
    margin: 0;
    width: 100%;
    display: flex;
    flex-flow: row;
    list-style: none;
    justify-content: space-between;
    li {
      margin: 0;
      flex: 1 0 auto;
      width: 20%;
      text-align: center;
      a {
        display: block;
        padding: 0.5rem 0.2rem 0.2rem;
        text-decoration: none;
        color: #5a5a5a;
      }
    }
  }
  ${mq.mobilemed} {
    font-size: 0.7rem;
  }
  ${mq.desktop} {
    display: none;
  }
`

const FixedBottomMenu = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpApiMenusMenusItems(name: { eq: "Hauptmenu" }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={data => (
      <FixedBottomMenuWrapper>
        <ul>
          <li>
            <Link
              to="/"
              activeStyle={{
                color: "#0D3D18",
              }}
            >
              <FontAwesomeIcon icon={faHome} size="2x" />
              <br />
              Startseite
            </Link>
          </li>
          <li>
            <Link
              to="/leistungen/"
              activeStyle={{
                color: "#0D3D18",
              }}
            >
              <FontAwesomeIcon icon={faHammer} size="2x" />
              <br />
              Leistungen
            </Link>
          </li>
          <li>
            <Link
              to="/ueber-uns/"
              activeStyle={{
                color: "#0D3D18",
              }}
            >
              <FontAwesomeIcon icon={faUsers} size="2x" />
              <br />
              Über Uns
            </Link>
          </li>
          <li>
            <Link
              to="/referenzen/"
              activeStyle={{
                color: "#0D3D18",
              }}
            >
              <FontAwesomeIcon icon={faImages} size="2x" />
              <br />
              Referenzen
            </Link>
          </li>
          <li>
            <Link
              to="/kontakt/"
              activeStyle={{
                color: "#0D3D18",
              }}
            >
              <FontAwesomeIcon icon={faPhone} size="2x" />
              <br />
              Kontakt
            </Link>
          </li>
        </ul>
      </FixedBottomMenuWrapper>
    )}
  />
)

export default FixedBottomMenu
